<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Write the products, the balanced molecular, total ionic, and net ionic equation for the
        chemical reaction described below. <b>Hint</b>: Don't forget to check the solubility table
        to see which compounds are not soluble (meaning they form solids).
      </p>

      <p class="mb-2">
        <stemble-latex
          content="$\text{Calcium chloride(aq) + ammonium nitrate(aq)}\ \rightarrow\ \textbf{Products:}$"
        />
      </p>
      <p class="mb-3">
        <v-text-field v-model="inputs.products" outlined />
      </p>

      <p>
        <b>Balanced Molecular Equation:</b>
      </p>
      <chemical-notation-input
        v-model="inputs.balanced"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />

      <p>
        <b>Total Ionic Equation:</b>
      </p>
      <chemical-notation-input
        v-model="inputs.totalIonic"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />

      <p>
        <b>Net Ionic Equation:</b>
      </p>
      <chemical-notation-input
        v-model="inputs.netIonic"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemCPP1210Equations4',
  components: {
    ChemicalNotationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        products: null,
        balanced: null,
        totalIonic: null,
        netIonic: null,
      },
    };
  },
};
</script>
